import { USER_ROLE } from "../../commons/enum";
import { useMyInfo } from "../../hooks/user.hooks";
import "./scss/dashboard.scss";

const DashboardContainer = () => {
  const { data: userInfo } = useMyInfo();
  const { userRole } = userInfo;
  return (
    <>
      {userRole === USER_ROLE.USER ? (
        <div>You don&apos;t have permission to view this page</div>
      ) : (
        <div>Dashboard</div>
      )}
    </>
  );
};

export default DashboardContainer;
